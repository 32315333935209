const validateForm = (form, options) => {
  form = form;
  let isFormValid = false;

  // set default values
  let defaults = {
    elementsSelector: '[required]',
    formFieldClass: 'form-field',
    showErrors: true,
    successIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.00003 16.1698L4.83003 11.9998L3.41003 13.4098L9.00003 18.9998L21 6.99984L19.59 5.58984L9.00003 16.1698Z" fill="#2ecc71"/></svg>`,
		errorIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="vertical-align:middle;"><path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#EF3340"/></svg>`,
  };

  let opt = Object.assign({}, defaults, options);



  const setError = (el, err) => {
    let formField = el.closest(`.${opt.formFieldClass}`),
        statusField = formField.querySelector(`.${opt.formFieldClass}__status`);

    console.error(err, el);

    formField.classList.remove('valid');
    formField.classList.add('validated', 'invalid');

    if (opt.showErrors) {
      statusField.innerHTML = `${opt.errorIcon} ${err}`;
    }
  }



  const clearError = el => {
    let formField = el.closest(`.${opt.formFieldClass}`),
        statusField = formField.querySelector(`.${opt.formFieldClass}__status`);

    formField.classList.remove('invalid');
    formField.classList.add('validated', 'valid');

    if (opt.showErrors) {
      statusField.innerHTML = `${opt.successIcon}`;
    }
  }



  const validate = el => {
    let value = el.value,
        // wrap the value in a span for the error message
        errorValue = `<span class="value">${value}</span>`,
        // get custom validation type first if present
        type = el.getAttribute('data-validation-type') || el.type || 'default';

    switch (type) {
      case 'checkbox':
      case 'radio':
        (el.checked) ? clearError(el) : setError(el, 'Not checked');
        break;

      case 'default':
      case 'text':
      case 'textarea':
      case 'file':
      case 'date':
      case 'time':
      case 'number':
      case 'image':
      case 'phone':
      case 'tel':
        (value === '' || value === undefined) ? setError(el, 'Element is empty') : clearError(el);
        break;

      // case 'phone':
      // case 'tel':
        // match phone number with leading +
        // (!value.match(/^\+*\d{8,15}$/)) ? setError(el, `${errorValue} is not a Phone number`) : clearError(el);
        // match phone numbers with country code. Either + or 00
        // (!value.match(/^((?:\+|00)(?:9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1){1})?(\d{1,14})$/)) ? setError(el, `${errorValue} is not a Phone number`) : clearError(el);
        // break;

      case 'email':
        let regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        (!value.match(regex)) ? setError(el, `${errorValue} is not a valid email address`) : clearError(el);
        break;

      case 'plz':
  			(!value.match(/^\d{5}$/)) ? setError(el, `${errorValue} is not a valid PLZ`) : clearError(el);
  			break;

  		default:
  			console.info('couldn\'t validate element', el);
    }

    checkCompletion();
  }



  // select elements which should be validated
  const elementsToValidate = form.querySelectorAll(opt.elementsSelector);

  elementsToValidate.forEach(el => {
    // get the parent container of each input
    let formField = el.closest(`.${opt.formFieldClass}`);

    // check if a status field is present
    let statusField = formField.querySelector(`.${opt.formFieldClass}__status`);

    // if not create one
    if (!statusField) {
      let statusEl = document.createElement('div');
      statusEl.classList.add(`${opt.formFieldClass}__status`);
      formField.appendChild(statusEl);
    }

    if (el.type === 'file' || el.type === 'checkbox' || el.type === 'radio' || el.type === 'date' || el.type === 'image' || el.type === 'number' || el.type === 'range' || el.type === 'time') {
      el.addEventListener('change', () => validate(el));
    }
    else {
      // validate on blur
      el.addEventListener('blur', () => validate(el));
      // or validate on keyup if the element was already validated (to prevent setting errors on first input)
      el.addEventListener('keyup', () => {
        if (formField.classList.contains('validated')) validate(el);
      });
    }

  });


  // validate the whole form if dsgvo checkbox is checked
  const dsgvo = form.querySelector('#dsgvo');
  if (dsgvo) {
    dsgvo.addEventListener('change', () => {
      elementsToValidate.forEach(el => validate(el));
    });
  }


  // get the submit button
  const submitButton = form.querySelector('[type="submit"]');

  const checkCompletion = () => {
    let complete = 0;

    elementsToValidate.forEach(el => {
      let formField = el.closest(`.${opt.formFieldClass}`);
      complete += (formField.classList.contains('valid')) ? 1 : 0;
    });

    if (complete == elementsToValidate.length) {
      // if the form is complete enable the submit button
      submitButton.disabled = false;
      isFormValid = true;
    } else {
      // else keep the submit buttn disabled
      submitButton.disabled = true;
      isFormValid = false;
    }
  }



  submitButton.addEventListener('click', () => {
    if (isFormValid) {
      submitButton.classList.add('loading');
    }
  });
}



export default validateForm;

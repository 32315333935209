import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const { select, selectAll, remToPx } = require('./../../../_global/js/utility/utils');
let mainNav, navBackground, burger, mainLogo, useLogo = false, hasOpening = false;



const openMenu = () => {
  mainNav.classList.add('open');
  burger.classList.add('open');
  // if (useLogo && hasOpening) mainLogo.querySelector('.logo').classList.remove('logo--white');
  document.documentElement.classList.add('overflow');

  let tl = gsap.timeline();

  // prevent translation by missing scrollbar
  // if (useLogo && hasOpening) {
  //   tl.set(mainLogo, {
  //     x: window.innerWidth >= 1200 ? -17 : 0,
  //   }, '-=1.2');
  // }


  tl.to(navBackground, {
    y: 0,
    duration: .8,
    ease: 'power4.inOut'
  });
  tl.to(mainNav, {
    y: 0,
    duration: .8,
    ease: 'power4.inOut'
  }, '-=.7');


  // animate nav content
  let menuItems = mainNav.querySelectorAll('.menu li');
  tl.from(menuItems, {
    y: 50,
    autoAlpha: 0,
    stagger: .05
  }, '-=.2');

  let menuBlocks = mainNav.querySelectorAll('.menu-blocks .block');
  tl.from(menuBlocks, {
    y: 100,
    autoAlpha: 0,
    duration: .4,
    stagger: .05
  }, '-=.6');


  // animate the logo
  // if the logo isn't at the top of the page yet move it up
  // if (useLogo && hasOpening) {
  //   if (!mainLogo.classList.contains('sticky')) {
  //     // store the current y position for later use
  //     let currentTop = mainLogo.getBoundingClientRect().top;
  //     mainLogo.setAttribute('data-top', currentTop);
  //
  //     tl.to(mainLogo, {
  //       y: `${window.innerWidth >= 1200 ? remToPx(3.75) : remToPx(1.25)}px`,
  //       x: window.innerWidth >= 1200 ? -17 : 0,
  //       duration: .8,
  //       ease: 'power3.inOut'
  //     }, '-=1.2');
  //   }
  // }
}





const closeMenu = () => {
  burger.classList.remove('open');
  document.documentElement.classList.remove('overflow');


  // if (useLogo && hasOpening) {
  //   if (mainLogo.getAttribute('data-version') === 'white') {
  //     mainLogo.querySelector('.logo').classList.add('logo--white');
  //   }
  // }

  let tl = gsap.timeline();

  // bring back to 0 when scrollbar is present again
  // if (useLogo && hasOpening) {
  //   tl.set(mainLogo, {
  //     x: 0,
  //   });
  // }

  tl.to(mainNav, {
    x: '-100%',
    duration: .6,
    ease: 'power2.inOut',
  });
  tl.to(navBackground, {
    x: '-100%',
    duration: .6,
    ease: 'power2.inOut',
    onComplete: () => {
      gsap.set(mainNav, {
        x: 0,
        y: '-100%'
      });
      gsap.set(navBackground, {
        x: 0,
        y: '-100%'
      });
      mainNav.classList.remove('open');
    }
  }, '-=.5');

  // animate the logo
  // if the logo wasn't on the top move it back to its original position
  // if (useLogo && hasOpening) {
  //   if (!mainLogo.classList.contains('sticky')) {
  //     tl.to(mainLogo, {
  //       y: `${mainLogo.getAttribute('data-top')}px`,
  //       x: 0,
  //       duration: .8,
  //       ease: 'power3.inOut',
  //     }, '-=.9');
  //   }
  // }
}





const toggleMenu = () => {
  mainNav.classList.contains('open') ? closeMenu() : openMenu();
}





const initNav = (withLogo = false) => {
  let opening = document.querySelector('.opening');
  if (opening) hasOpening = true;

  mainNav = document.querySelector('.main-header');
  if (!mainNav) return console.log('main header is missing');

  navBackground = document.querySelector('.nav-background');

  burger = document.querySelector('.burger');
  if (!burger) return console.log('burger is missing');

  // if (withLogo && hasOpening) {
  //   useLogo = withLogo;
  //   mainLogo = document.querySelector('.main-logo');
  //
  //   mainLogo.querySelector('.logo').classList.add('logo--white');
  //   mainLogo.setAttribute('data-version', 'white');
  // }


  burger.addEventListener('click', toggleMenu);
  navBackground.addEventListener('click', closeMenu);

  // if (withLogo && hasOpening) {
  //   // dont move logo to right bottom if the screen is too small
  //   // else the logo would be on top of the opening text
  //   // also dont use the scrollTrigger
  //   let previousScrollTrigger = ScrollTrigger.getById('mainLogoTrigger');
  //   if (previousScrollTrigger) {
  //     ScrollTrigger.getById('mainLogoTrigger').kill(true);
  //     gsap.set(mainLogo, {clearProps: true});
  //   }
  //   if (window.innerWidth >= 1650) {
  //     gsap.set(mainLogo, {
  //       y: (window.innerHeight - mainLogo.offsetHeight) - remToPx(3.75)
  //     });
  //     // mainLogo scrollTrigger
  //     gsap.to(mainLogo, {
  //       y: remToPx(3.75),
  //       ease: 'none',
  //       scrollTrigger: {
  //         id: 'mainLogoTrigger',
  //         trigger: 'body',
  //         scrub: true,
  //         // markers: true,
  //         start: 'top top',
  //         end: `${window.innerHeight}px top`
  //       },
  //       onComplete: () => {
  //         mainLogo.classList.add('sticky');
  //       }
  //     });
  //   }
  //
  //   window.addEventListener('scroll', () => {
  //     if (window.pageYOffset < window.innerHeight) {
  //       mainLogo.classList.remove('sticky');
  //     }
  //
  //     // don't change the logo color if the nav is open
  //     if (mainNav.classList.contains('open')) return;
  //
  //     // if the logo is halfway at the bottom of the opening (1 viewport) remove the white class
  //     if (mainLogo.getBoundingClientRect().top >= ((window.innerHeight - (mainLogo.offsetHeight / 2)) - window.pageYOffset)) {
  //       mainLogo.setAttribute('data-version', 'default');
  //       mainLogo.querySelector('.logo').classList.remove('logo--white');
  //     } else {
  //       mainLogo.setAttribute('data-version', 'white');
  //       mainLogo.querySelector('.logo').classList.add('logo--white');
  //     }
  //   });
  // }
}



const removeNavActiveClass = (current = document) => {
  const navLinks = selectAll('.menu li a', mainNav);
  navLinks.forEach(link => {
    let parentListItem = link.closest('li');
    parentListItem.classList.remove('current-menu-item');
    link.classList.remove('active');
  });
}



const addNavActiveClass = (next = document) => {
  const targetLink = select(`.menu li a[data-site="${next.namespace}"]`, mainNav);

  if (targetLink == null) return console.log('couldn\'t find the target nav link');

  const parentListItem = targetLink.closest('li');

  parentListItem.classList.add('current-menu-item');
  targetLink.classList.add('active');
}



export { openMenu, closeMenu, toggleMenu, initNav, removeNavActiveClass, addNavActiveClass };

let animationDuration = 1,
		easing = 'power4.out';


/*
 * change the clicked item in a select element
 */
const changeSelection = (select, value) => {
	const originalEl = select.querySelector('select');
	const styledEl = select.querySelector('.select__styled');
	const optionsEl = select.querySelector('.select__options');

	originalEl.value = value;
	styledEl.querySelector('.content').innerHTML = value;
	styledEl.setAttribute('data-value', value);

	const options = optionsEl.querySelectorAll('li');
	options.forEach(option => {
		option.classList.remove('selected');
	});
	optionsEl.querySelector(`[data-value="${value}"]`).classList.add('selected');
	closeSelect(select);
}


/*
 * Open a select options container
 */
const openSelect = (select) => {
	const optionsEl = select.querySelector('.select__options');
	select.classList.add('open');
	if (typeof gsap !== 'undefined') {
		gsap.killTweensOf(optionsEl);
		gsap.set(optionsEl, {
			height: 'auto'
		});
		gsap.from(optionsEl, {
			height: 0,
			duration: animationDuration,
			ease: easing
		})
	} else {
		optionsEl.style.height = 'auto';
	}
}


/*
 * Close a select options container
 */
const closeSelect = (select) => {
	const optionsEl = select.querySelector('.select__options');
	select.classList.remove('open');
	if (typeof gsap !== 'undefined') {
		gsap.killTweensOf(optionsEl);
		gsap.to(optionsEl, {
			height: 0,
			duration: animationDuration * .5,
			ease: easing
		})
	} else {
		optionsEl.style.height = '0px';
	}
}


const closeAllSelects = (target) => {
	const selects = document.querySelectorAll('.select');
	selects.forEach(select => {
		if (target.path.includes(select)) return;
		closeSelect(select);
	});
}


/*
 * Toggle a select options container
 */
const toggleSelect = (select) => {
	select.classList.contains('open') ? closeSelect(select) : openSelect(select);
}


/*
 * loop
 */
const initCustomSelect = () => {
  const selects = document.querySelectorAll('select');

  selects.forEach(select => {
    const parentContainer = select.parentNode;
    const label = parentContainer.querySelector('label');

  	select.classList.add('select--hidden');

  	const options = select.querySelectorAll('option');

  	/* create the wrapper element */
  	let wrap = document.createElement('div');
  	wrap.classList.add('select');
  	wrap.innerHTML = select.outerHTML;

    /* add a required attribute if the original select element is required | for css use */
    if (select.hasAttribute('required')) wrap.classList.add('required');

  	/* create the styled select element */
  	const styledSelect = document.createElement('div');
  	styledSelect.classList.add('select__styled');

  	const styledSelectContent = document.createElement('span');
  	styledSelectContent.classList.add('content');

  	const arrow = document.createElement('span');
  	arrow.classList.add('arrow');
  	arrow.innerHTML = `<svg width="12" height="5" viewBox="0 0 12 5" fill="none" xmlns="http://www.w3.org/2000/svg">
  											<path d="M0 2L6 5L12 2V0L6 3L0 0V2Z" fill="currentColor"/>
  											</svg>
  											`;

  	styledSelectContent.innerHTML = select.value;

  	styledSelect.appendChild(styledSelectContent);
  	styledSelect.appendChild(arrow);
  	styledSelect.setAttribute('data-value', select.value);

  	/* create the styled options list */
  	const selectOptions = document.createElement('div');
  	selectOptions.classList.add('select__options');

  	const selectOptionsList = document.createElement('ul');
  	selectOptionsList.classList.add('select__options__list');

  	options.forEach(option => {
  		const optionEl = document.createElement('li');
  		const value = option.value || option.textContent;
  		optionEl.setAttribute('data-value', value);
  		optionEl.innerHTML = option.textContent;
  		if (value === select.value) optionEl.classList.add('selected');
  		selectOptionsList.appendChild(optionEl);

  		optionEl.addEventListener('click', () => {
  			changeSelection(wrap, value);
  		});
  	});

  	selectOptions.appendChild(selectOptionsList);

  	/* append the new elements */
  	wrap.appendChild(styledSelect);
  	wrap.appendChild(selectOptions);

  	/* append the new select element to the body */
  	// parentContainer.prependChild(wrap);
    parentContainer.insertBefore(wrap, label)


  	styledSelect.addEventListener('click', () => {
  		toggleSelect(wrap);
  	});


  	select.remove();
  });


	document.addEventListener('click', closeAllSelects);
}


export default initCustomSelect;

// import initRevealContent from './../../../_global/js/components/revealContent';
import initCustomSelect from './../../../_global/js/components/customSelect';
import checkDsgvoStatus from './../../../_global/js/components/dsgvoCheck';
import Swiper, { HashNavigation } from 'swiper';

Swiper.use([HashNavigation]);

let productSwiper,
    direction = 'horizontal',
    oldDirection = direction;



const trackItems = () => {
  productSwiper.on('slideChange', () => {
    let activeSlideElement = productSwiper.slides[productSwiper.activeIndex];
    let activeSlideSlug = activeSlideElement.getAttribute('id');
    highlightProductListItem(activeSlideSlug);
  });
}



const highlightProductListItem = (activeItemSlug) => {
  const productList = document.querySelector('.product-list__list');
  const productListItems = productList.querySelectorAll('li');
  const activeListItem = productList.querySelector(`a[href="#${activeItemSlug}"] `).parentNode;

  productListItems.forEach(item => {
    if (item === activeListItem) return;
    item.classList.remove('active');
  });

  activeListItem.classList.add('active');
}



const resetProductSwiper = () => {
  let slideIndex = productSwiper.activeIndex;
  productSwiper.destroy(true, true);
  productSwiper = createProductSwiper(direction);
  productSwiper.slideTo(slideIndex, 0);

  trackItems();
}


const checkWidthForSwiper = () => {
  // direction = (window.innerWidth < 1200) ? 'horizontal' : 'vertical';

  if (direction != oldDirection) {
    resetProductSwiper();
    oldDirection = direction;
  }
}



const createProductSwiper = (direction) => {
  return new Swiper('.product-swiper', {
    direction: direction,
    hashNavigation: true,
    autoHeight: true
  });
}



const initProductSwiper = () => {
  productSwiper = createProductSwiper(direction);

  // mark active item in list on initial visit
  let activeSlideElement = productSwiper.slides[productSwiper.activeIndex];
  let activeSlideSlug = activeSlideElement.getAttribute('id');
  highlightProductListItem(activeSlideSlug);

  resetProductSwiper();
  checkWidthForSwiper();

  window.addEventListener('resize', function() {
    checkWidthForSwiper();
    productSwiper.updateSize();
  });
}










const handleProductListClicks = () => {
  const productList = document.querySelector('.product-list__list');
  const productListItems = productList.querySelectorAll('li a');

  productListItems.forEach(item => {
    item.addEventListener('click', function(e) {
      e.preventDefault();
      let targetSlug = item.getAttribute('href').replace('#', '');
      let slides = [...productSwiper.slides];
      let targetSlide = slides.find(item => item.getAttribute('id') === targetSlug);
      let targetSlideIndex = slides.indexOf(targetSlide);
      productSwiper.slideTo(targetSlideIndex, 500);
    });
  });
}









/*
 * barba view
 */
const Products = {
  namespace: 'products',
  beforeEnter: (data) => {

  },
  afterEnter: (data) => {

    // initRevealContent();
    initCustomSelect();
    checkDsgvoStatus(data.next.container);
    initProductSwiper();
    handleProductListClicks();
  }
}

export default Products;

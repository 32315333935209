import initAccordeon from './../../../_global/js/components/accordeon';
import validateForm from './../../../_global/js/components/validateForm';
import Swiper, { Controller, Pagination } from 'swiper';
Swiper.use([Controller, Pagination]);


function initTestimonialSwipers() {
  console.log('start swiper');
  var testimonialTextSlider = new Swiper('.testimonals__text__swiper', {
    direction: 'horizontal',
    allowTouchMove: false,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    autoHeight: true,
  });

  var testimonialImageSlider = new Swiper('.testimonals__images__swiper', {
    direction: 'horizontal',
    allowTouchMove: true,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    autoHeight: true,
    controller: {
      control: testimonialTextSlider
    },
  });

  window.addEventListener('resize', () => {
    testimonialTextSlider.updateSize();
    testimonialImageSlider.updateSize();
  });
}


/*
 * barba view
 */
const Home = {
  namespace: 'home',
  beforeEnter: (data) => {


    // if (data.current.container === null) {
    //   initAccordeon();
    //
    //   initTestimonialSwipers();
    //   window.addEventListener('resize', initTestimonialSwipers);
    // }
  },
  afterEnter: (data) => {

    initAccordeon();

    let formsToValidate = document.querySelectorAll('form[data-validate]');
    formsToValidate.forEach(form => validateForm(form, {
    	showErrors: false,
    }));

    initTestimonialSwipers();
    window.addEventListener('resize', initTestimonialSwipers);
  }
}

export default Home;

const select = (e, p = document) => p ? p.querySelector(e) : null;

const selectAll = (e, p = document) => p ? p.querySelectorAll(e) : null;

const selectId = (e, p = document) => p ? p.getElementById(e) : null;

const getRootElementFontSize = () => parseFloat(getComputedStyle(document.documentElement).fontSize);

const remToPx = (v) => v * getRootElementFontSize();

const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;

const lerp = (a, b, n) => (1 - n) * a + n * b;

const randomInRange = (min, max) => Math.floor((Math.random() * (max - min + 1)) + min);

const randomInRangeDecimal = (min, max) => Math.round((Math.random() * ((max * 10) - (min * 10) + 1)) + (min * 10)) / 10;

const ready = (callback) => {
  if (document.readyState != 'loading') callback();
  else document.addEventListener('DOMContentLoaded', callback);
}

const makeId = length => {
  let result = '',
      chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
      charsLength = chars.length;
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charsLength));
  }
  return result;
}

const shuffle = arr => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

const scrollTo = (offset, callback) => {
  const fixedOffset = offset.toFixed(),
        onScroll = () => {
          if (window.pageYOffset.toFixed() === fixedOffset) {
            window.removeEventListener('scroll', onScroll);
            callback();
          }
        }

  window.addEventListener('scroll', onScroll);
  onScroll();
  window.scrollTo({
    top: offset,
    behavior: 'auto'
  });
}

export { select, selectAll, selectId, remToPx, map, lerp, randomInRange, randomInRangeDecimal, ready, makeId, shuffle, scrollTo };

import validateForm from './../../../_global/js/components/validateForm';



/*
 * barba view
 */
const BookOrder = {
  namespace: 'bookOrder',
  afterEnter: (data) => {
    let formsToValidate = document.querySelectorAll('form[data-validate]');
    formsToValidate.forEach(form => validateForm(form, {
    	showErrors: false,
    }));
  }
}

export default BookOrder;

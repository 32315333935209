import {selectAll} from './../utility/utils';
import { gsap } from 'gsap';

let duration = .25,
    ease = 'power1.easeInOut';


/*
 * Open one Accordeon Row's Content
 */
const openRow = (row) => {
  let content = row.querySelector('.accordeon__content');
  row.classList.add('open');
  gsap.set(content, {
    height: 'auto'
  });
  gsap.from(content, {
    height: 0,
    duration: duration,
    ease: ease
  });
}


/*
 * Close the Accordeon Row's Content
 */
const closeRow = (row) => {
  row.classList.remove('open');
  let content = row.querySelector('.accordeon__content');
  gsap.to(content, {
    height: 0,
    duration: duration,
    ease: ease
  });
}


/*
 * Initialize all Accordeon Lists
 */
const initAccordeon = () => {
  /* get all accordeon lists */
  let accordeons = selectAll('.accordeon');

  /* loop each accordeon list */
  accordeons.forEach(accordeon => {
    /* get all rows in the accordeon list */
    let rows = accordeon.querySelectorAll('.accordeon__row');

    if (rows.length <= 0) return;

    rows.forEach(row => {
      let title = row.querySelector('.accordeon__title');

      title.addEventListener('click', function(e) {
        e.preventDefault();

        if (row.classList.contains('open')) {
          closeRow(row);
        } else {
          rows.forEach(r => {
            if (r === row) return;
            closeRow(r);
          });
          openRow(row);
        }
      });
    });

    /* open the first row by default */
    rows.forEach(r => {
      if (r === rows[0]) return;
      closeRow(r);
    });
    openRow(rows[0]);
  });
}

export default initAccordeon;

import { gsap } from 'gsap';



let useGsap = false;



/**
 * # Open Modals
 * Open a specific modal
 * 
 * @param {Element} modal - The modal to open
 */
const openModal = (modal) => {
  // get the parent modal container
  const modalContainer = modal.closest('.modal-container');

  // add classes
  modalContainer.classList.add('open');
  modal.classList.add('open');

  // prevent the website from scrolling while the modal is open
  document.documentElement.classList.add('overflow');

  // if gsap should be used animate the modal
  if (useGsap) {
    const tl = gsap.timeline();

    tl.set(modalContainer, {
      y: 0
    });

    tl.to(modalContainer, {
      autoAlpha: 1,
      duration: .4
    });

    tl.to(modal, {
      y: 0,
      autoAlpha: 1,
      duration: .4,
      ease: 'power3.out'
    }, '-=.2');
  }
}



/**
 * # Close Modal
 * Close a specific modal
 * 
 * @param {Element} modal - The modal to close
 */
const closeModal = (modal) => {
  // get the parent modal container
  const modalContainer = modal.closest('.modal-container');

  // add classes
  modalContainer.classList.remove('open');
  modal.classList.remove('open');

  document.documentElement.classList.remove('overflow');

  // if gsap should be used animate the modal
  if (useGsap) {
    const tl = gsap.timeline();

    tl.to(modal, {
      y: 200,
      autoAlpha: 0,
      duration: .4,
      ease: 'power3.in'
    });

    tl.to(modalContainer, {
      autoAlpha: 0,
      duration: .4,
      onComplete: () => {
        tl.set(modalContainer, {
          y: '100vh'
        });
      }
    }, '-=.2');
  }
}



/**
 * # Toggle Modal
 * Runs the open/close function based on the current state of the modal
 * 
 * @param {Element} modal - The modal to open/close
 */
const toggleModal = (modal) => {
  modal.classList.contains('open') ? closeModal(modal) : openModal(modal);
}



/**
 * # Init Modals
 * Initializes all modals based on their triggers
 * 
 * @param {boolean} gsap - If the function should use gsap for animation or not
 */
const initModals = (gsap = false) => {
  // check if gsap should be used
  useGsap = gsap;

  // get all modal triggers on the page
  const modalTriggers = document.querySelectorAll('.modal-trigger');

  modalTriggers.forEach(trigger => {
    // if trigger is clicked
    trigger.addEventListener('click', e => {
      e.preventDefault();
      // get the target link
      const target = trigger.getAttribute('href');
      // if no target is provided it is a trigger inside the modal
      const modal = target === null ? trigger.closest('.modal') : document.querySelector(target);

      toggleModal(modal);
    });
  });



  // get all modal containers on the page
  const modalContainers = document.querySelectorAll('.modal-container');

  modalContainers.forEach(container => {
    // add the animate class to disable the default css animation
    container.classList.add('animate');

    // if the container gets clicked close the modal
    container.addEventListener('click', e => {
      if (e.target != container) return; // dont close modal on click inside modal only on click of container

      // get the child modal
      const modal = container.querySelector('.modal');

      closeModal(modal);
    });
  });
}



export { openModal, closeModal, toggleModal, initModals };

import initCustomSelect from './../../../_global/js/components/customSelect.js';
import initFileInputs from './../../../_global/js/components/fileInputs';
import validateForm from './../../../_global/js/components/validateForm';



const initForm = () => {
  let application = document.querySelector('#application');
  if (!application) return;


  let fileInputs = document.querySelectorAll('.inputfile');

  fileInputs.forEach(input => {
    let label = input.nextElementSibling,
        labelVal = label.innerHTML;

    input.addEventListener('change', function(e) {
      let fileName = '';
      if (this.files && this.files.length > 1) {
        fileName = (this.getAttribute('data-multiple-caption') || '').replace('{ count }', this.files.length);
      } else {
        fileName = e.target.value.split('\\').pop();
      }

      if (fileName) {
        label.querySelector('span').innerHTML = fileName;
      } else {
        label.innerHTML = labelVal;
      }

      (this.files && this.files.length > 0) ? clearError(input) : setError(input, 'no file');
      checkProgress(input);
    });
  });



  // check for dsgvo checkbox
  let dsgvoCheckbox = document.querySelector('#dsgvo'),
      submitBtn = document.querySelector('#submitBtn');

  dsgvoCheckbox.addEventListener('change', () => dsgvoCheckbox.checked ? submitBtn.disabled = false : submitBtn.disabled = true);



  let errors = true;
  const checkForErrors = () => {
    // TODO:
  }



  const checkProgress = el => {
    const appSection = el.closest('.app__section');
    let inputs = appSection.querySelectorAll('.form-field[data-validate]'),
        complete = 0;

    inputs.forEach((input, i) => {
      complete += (input.classList.contains('valid')) ? 1 : 0;
    });

    complete == inputs.length
      ? appSection.classList.add('app__section--complete')
      : appSection.classList.remove('app__section--complete');
  }



  const setError = (el, err) => {
    const inputField = el.parentNode;
    const errorField = inputField.querySelector('.form-field__status');

    console.warn(`input #${el.getAttribute('id')} is invalid |`, err);

    inputField.classList.remove('valid');
    inputField.classList.add('form-field--error', 'validated');

    if (!errorField) return;
    errorField.innerHTML = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#EF3340"/>
                            </svg>`;
  }



  const clearError = el => {
    const inputField = el.parentNode;
    const errorField = inputField.querySelector('.form-field__status');

    inputField.classList.remove('form-field--error');
    inputField.classList.add('validated', 'valid');

    if (!errorField) return;
    errorField.innerHTML = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.00003 16.1698L4.83003 11.9998L3.41003 13.4098L9.00003 18.9998L21 6.99984L19.59 5.58984L9.00003 16.1698Z" fill="#2ecc71"/>
                            </svg>`;
  }



  // validate elements
  const validate = (el, type) => {
    let value = el.value;

    console.log('validate', type);

    switch (type) {
      case 'text':
        (value === '' || value === undefined) ? setError(el, 'input is empty') : clearError(el);
        break;

      case 'plz':
        let plzRegex = /^\d{5}$/;
        (!value.match(plzRegex)) ? setError(el, 'field contains illegal characters') : clearError(el);
        break;

      case 'phone':
        let phoneRegex = /^\d{8,15}$/;
        (!value.match(phoneRegex)) ? setError(el, 'no phone') : clearError(el);
        break;

      case 'email':
        let emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        (!value.match(emailRegex)) ? setError(el, 'input is not a email address') : clearError(el);

      default:
        console.log('default');
    }

    checkProgress(el);
  }



  // loop through elements which require a validation
  const elementsToValidate = document.querySelectorAll('[data-validate]');
  elementsToValidate.forEach(el => {
    // get the validation type
    let validationType = el.getAttribute('data-validate');
    const inputField = el.closest('.form-field');
    inputField.setAttribute('data-validate', '');

    // validate on blur
    el.addEventListener('blur', () => validate(el, validationType));
    // or validate on keyup if the element was already validated (to prevent setting errors on first input)
    el.addEventListener('keyup', () => {
      if (el.parentNode.classList.contains('validated')) {
        validate(el, validationType);
      }
    });
  });
}



/*
 * barba view
 */
const Career = {
  namespace: 'career',
  beforeEnter: (data) => {

  },
  afterEnter: (data) => {

    initCustomSelect();
    initFileInputs();
    // initForm();

    let formsToValidate = document.querySelectorAll('form[data-validate]');
    formsToValidate.forEach(form => validateForm(form, {
    	showErrors: false,
    }));
  }
}

export default Career;

const checkDsgvoStatus = (container = document) => {
  const dsgvoCheck = container.querySelector('#dsgvo');
  const submitBtn = container.querySelector('#submitbtn');

  if (!dsgvoCheck) return;
  if (!submitBtn) return;
  
  dsgvoCheck.addEventListener('change', () => {
    submitBtn.disabled = dsgvoCheck.checked ? false : true;
  });
}



export default checkDsgvoStatus;

const initFileInputs = () => {
  const fileInputs = document.querySelectorAll('.inputfile');

  fileInputs.forEach(input => {
    const label = input.nextElementSibling;
    let labelVal = label.innerHTML;

    input.addEventListener('change', function(e) {
      let fileName = '';
      if (this.files && this.files.length > 1) {
        fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
      } else {
        fileName = e.target.value.split('\\').pop();
      }

      if (fileName) {
        label.querySelector('span').innerHTML = fileName;
      } else {
        label.innerHTML = labelVal;
      }

      // (this.files && this.files.length > 0) ? clearError(input) : setError(input, 'no file');
      // checkProgress(input);
    });
  });
}



export default initFileInputs;

let observer;



function onEntry(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('visible');
      entry.target.setAttribute('data-scroll', 'in');
    }
  });
}



const initSlideInElements = () => {
  let options = {
    threshold: [0.5]
  };

  observer = new IntersectionObserver(onEntry, options);
}



const observeElements = () => {
  console.log('observe Elements');
  const elements = document.querySelectorAll('.scrollIn, .splitting');
  elements.forEach(el => observer.observe(el));
}



const unobserveElements = () => {
  console.log('unobserve Elements');
  const elements = document.querySelectorAll('.scrollIn, .splitting');
  elements.forEach(el => observer.unobserve(el));
}




export { initSlideInElements, observeElements, unobserveElements };

import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);


const initPartnerSliders = () => {
  const partnerSwiper1 = new Swiper('#partner1', {
    direction: 'horizontal',
    slidesPerView: 3,
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  const partnerSwiper2 = new Swiper('#partner2', {
    direction: 'horizontal',
    slidesPerView: 3,
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
}



/*
 * barba view
 */
const Partners = {
  namespace: 'partners',
  beforeEnter: (data) => {

  },
  afterEnter: (data) => {
    initPartnerSliders();
  }
}

export default Partners;

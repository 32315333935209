/*! @license
 * STV/TVS
 *
 * copyright 2020 MedienMacher BWG (https://medienmacher-bwg.de/)
*/

// add js class to html element if js is supported
document.documentElement.classList.add('js');
if (process.env.NODE_ENV !== 'production') console.log('We\'re in dev mode!');

import barba from '@barba/core';
import 'lazysizes';
import Splitting from "splitting";
import { gsap } from 'gsap';

import { select, selectAll, selectId, ready, remToPx, scrollTo } from './../../_global/js/utility/utils';
import { initNav, closeMenu, removeNavActiveClass, addNavActiveClass } from './../../_global/js/components/nav';

import { initSlideInElements, observeElements, unobserveElements } from './../../_global/js/utility/slideInElements';

import { initModals } from './../../_global/js/components/modal';

Splitting();

initNav();
window.addEventListener('resize', initNav);



const finishedScrolling = new Event('finishedScrolling');



/********************
 * current
 ********************/
barba.hooks.before(() => {
barba.wrapper.classList.add('is-animating');
});



barba.hooks.beforeLeave(({ next }) => {
  // scroll to the top
  let scrollPos = 0;
  if (next.url.hash != undefined) {
    const target = select(`#${next.url.hash}`);
    const targetOffset = target ? target.getBoundingClientRect().top : 0;
    scrollPos = targetOffset + window.pageYOffset;
  }
  // window.scrollTo(0, scrollPos);
  scrollTo(scrollPos, () => {
    document.dispatchEvent(finishedScrolling);
  });
});



barba.hooks.leave(({ current }) => {
  removeNavActiveClass(current);
});



barba.hooks.afterLeave(({ current, next }) => {
  // remove old namespace from body classList
  document.body.classList.remove(current.namespace);

  // replace old body classes with the new ones
  let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
      match = regexp.exec(next.html);
  if (!match || !match[1]) return;
  document.body.setAttribute('class', match[1]);
});



/********************
 * next
 ********************/
barba.hooks.beforeEnter(({ current, next }) => {
  // add new namespace to body classList
  document.body.classList.add(next.namespace);

  // get new colors
  let newMain = next.container.dataset.main || '#ffd48a';
  let newHeaderMain = next.container.dataset.headermain || '#f49900';
  document.documentElement.style.setProperty('--main', newMain);
  document.body.style.setProperty('--main', newMain);
  document.documentElement.style.setProperty('--header-main', newHeaderMain);
  document.body.style.setProperty('--header-main', newHeaderMain);

  // evaluate email obfuscator scripts
  const scripts = selectAll('script', next.container);
  scripts.forEach(script => {
    if (script.id === '') return;
    if (script.id.startsWith('script_enkoder_')) eval(script.innerHTML);
  });

  // // scroll to the top
  // let scrollPos = 0;
  // if (next.url.hash != undefined) {
  //   const target = select(`#${next.url.hash}`);
  //   const targetOffset = target ? target.getBoundingClientRect().top : 0;
  //   scrollPos = targetOffset + window.pageYOffset;
  // }
  // // window.scrollTo(0, scrollPos);
  // scrollTo(scrollPos, () => {
  //   document.dispatchEvent(finishedScrolling);
  // });
});



barba.hooks.enter(({ next }) => {
  addNavActiveClass(next);
});



barba.hooks.afterEnter(() => {
});



barba.hooks.after(() => {
  barba.wrapper.classList.remove('is-animating');
});



const pageTransitionEnd = new Event('pageTransitionEnd');
const transitionOverlay = document.querySelector('.transition-overlay');
let duration = .6;



/* import barba views */
import Home from './pages/home';
import Products from './pages/products';
import Career from './pages/career';
import Partners from './pages/partners';
import Contact from './pages/contact';
import BookOrder from './pages/bookOrder';



barba.init({
  debug: true,
  prevent: ({ el }) => el.classList && el.classList.contains('prevent'),

  views: [Home, Products, Career, Partners, Contact, BookOrder],

  transitions: [{
    name: 'default-fade',



    once: ({ next }) => {
      console.log('##########once');
      document.dispatchEvent(pageTransitionEnd);
      document.dispatchEvent(finishedScrolling);

      initSlideInElements();
      observeElements();
      initModals(true);
    },




    leave: ({ current }) => {
      closeMenu();

      unobserveElements();

      return gsap.to(transitionOverlay, {
        y: 0,
        duration: duration
      });
    },



    beforeEnter: ({ next }) => {
      next.container.style.zIndex = -1;
    },



    enter: ({ next }) => {
      observeElements();

      const endTransition = () => {
        next.container.removeEventListener('finishedScrolling', endTransition);
        gsap.to(transitionOverlay, {
          y: '100%',
          duration: duration,
          onComplete: () => {
            document.dispatchEvent(pageTransitionEnd);
            gsap.set(transitionOverlay, {
              y: '-100%'
            });
          }
        });
      }
      // if (window.pageYOffset === 0) {
      //   console.log('is 0');
      // } else {
      //   console.log('is not 0');
      // }
      // return (window.pageYOffset === 0) ? endTransition() : next.container.addEventListener('finishedScrolling', endTransition);
      return endTransition();
    },



  }],
});
